import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { IMailingAddressJson } from "@smartrr/shared/entities/MailingAddress";
import { PurchaseStateCreateShippingInfoInput } from "@smartrr/shared/entities/PurchaseState";
import { SubscriptionBillingPolicyInput } from "@smartrr/shared/shopifyGraphQL/api";
import {
  IDeserializedPaginatedQuery,
  ILoadPaginatedForOrg,
  serializePaginatedQueryValues,
} from "@smartrr/shared/utils/paginatedQuery";
import { toViewDate } from "@smartrr/shared/utils/renderViewDate";

import { getQueryParamsForFilters } from "@vendor-app/app/_sharedComponents/TableSearch/libs";
import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { DEFAULT_PAGE_SIZE } from "@vendor-app/app/AdminRoute/AdminSellingPlanGroupsRoute/constants";
import { ILineItemToAdd, ILineItemToUpdate } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { TableSettingProps } from "@vendor-app/constants/table";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export function putCustomerPurchaseStateShippingPriceAPI(custRelId: string, cpsId: string, price: number) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/shipping-price",
    {
      reqBody: {
        price,
      },
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
      },
    }
  );
}

export function putCustomerPurchaseStateLineItemAPI(
  custRelId: string,
  cpsId: string,
  lineItemId: string,
  price: number,
  quantity: number
) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/line-item/:lineItemId",
    {
      reqBody: {
        price,
        quantity,
      },
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
        lineItemId,
      },
    }
  );
}

// for deleting line items from vendor portal (does this create possibility of empty active subscriptions?)
export function deleteCustomerPurchaseStateLineItemAPI(custRelId: string, cpsId: string, lineItemId: string) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/line-item/:lineItemId/remove",
    {
      params: {
        customerRelationshipId: custRelId,
        customerPurchaseStateId: cpsId,
        lineItemId,
      },
    }
  );
}

export function addCustomerPurchaseStateLineItemAPI(
  custRelId: string,
  cpsId: string,
  quantity: number,
  price: number,
  variantId: string,
  sellingPlanId: string | number,
  skdIdx?: number
) {
  return typedFrontendVendorApi.postReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/line-item",
    {
      reqBody: {
        quantity,
        price,
        variantId,
        sellingPlanId,
        skdIdx,
      },
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
      },
    }
  );
}

export function bulkAddCustomerPurchaseStateLineItemAPI(
  custRelId: string,
  cpsId: string,
  lineItems: ILineItemToAdd[]
) {
  return typedFrontendVendorApi.postReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/bulk-add-line-items",
    {
      reqBody: { lineItems },
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
      },
    }
  );
}
export function bulkUpdateCustomerPurchaseStateLineItemAPI(
  custRelId: string,
  cpsId: string,
  lineItems: ILineItemToUpdate[]
) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/bulk-update-line-items",
    {
      reqBody: { lineItems },
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
      },
    }
  );
}
export function bulkRemoveCustomerPurchaseStateLineItemAPI(
  custRelId: string,
  cpsId: string,
  lineItemIds: string[]
) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/bulk-remove-line-items",
    {
      reqBody: { lineItemIds },
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
      },
    }
  );
}

export function bulkSkipCustomerPurchaseStateAPI(custRelId: string, cpsId: string, dates: ISODateString[]) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/bulk-skip",
    {
      reqBody: { dates },
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
      },
    }
  );
}

export function bulkUnskipCustomerPurchaseStateAPI(custRelId: string, cpsId: string, dates: ISODateString[]) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/bulk-unskip",
    {
      reqBody: { dates },
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
      },
    }
  );
}

export function putCustomerPurchaseStateSellingPlanAPI(custRelId: string, cpsId: string, sellingPlanId: number) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/selling-plan",
    {
      params: {
        customerRelationshipId: custRelId,
        customerPurchaseStateId: cpsId,
      },
      reqBody: {
        sellingPlanId,
      },
    }
  );
}

export function putCustomerPurchaseStateSkipAPI(custRelId: string, cpsId: string, date: Date) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/skip",
    {
      reqBody: {
        date: ISODateString.toString(date),
      },
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
      },
    }
  );
}

export function putCustomerPurchaseStateUnskipAPI(custRelId: string, cpsId: string, date: Date) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/unskip",
    {
      reqBody: {
        date: ISODateString.toString(date),
      },
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
      },
    }
  );
}

export function putCustomerPurchaseStateSetNextBillingDateAPI(
  custRelId: string,
  cpsId: string,
  billingDate: string
) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/next-billing-date",
    {
      reqBody: {
        date: billingDate,
      },
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
      },
    }
  );
}

export function postCustomerPurchaseStateApplyDiscountCodeAPI(
  custRelId: string,
  cpsId: string,
  redeemCode: string
) {
  return typedFrontendVendorApi.postReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/discount",
    {
      reqBody: {
        redeemCode,
      },
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
      },
    }
  );
}

export function putCustomerPurchaseStateUpdateNote(cpsId: string, noteText?: string) {
  return typedFrontendVendorApi.putReq("/purchase-state/:customerPurchaseStateId/note", {
    reqBody: {
      noteText,
    },
    params: {
      customerPurchaseStateId: cpsId,
    },
  });
}

export function deleteCustomerPurchaseStateDiscountAPI(
  custRelId: string,
  cpsId: string,
  discountVendorId: string
) {
  return typedFrontendVendorApi.deleteReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/discount/:discountVendorId",
    {
      params: {
        customerPurchaseStateId: cpsId,
        customerRelationshipId: custRelId,
        discountVendorId,
      },
    }
  );
}

export function getCustomerPurchaseStateByIdOrShopifyId(shopifySubscriptionId: string) {
  return typedFrontendVendorApi.getReq("/purchase-state/:shopifySubscriptionId", {
    params: {
      shopifySubscriptionId,
    },
  });
}

export function getCustomerPurchaseStates({ queryParams }: ILoadPaginatedForOrg) {
  return typedFrontendVendorApi.getReq("/purchase-state", {
    query: serializePaginatedQueryValues(queryParams),
  });
}

export function getCancelledCustomerPurchaseStates({ queryParams }: ILoadPaginatedForOrg) {
  return typedFrontendVendorApi.getReq("/purchase-state/cancelled", {
    query: serializePaginatedQueryValues(queryParams),
  });
}

export function getCustomerPurchaseStateLineItems({ queryParams }: ILoadPaginatedForOrg) {
  return typedFrontendVendorApi.getReq("/purchase-state-line-item", {
    query: serializePaginatedQueryValues(queryParams),
  });
}

export function postCreateSubscription(reqBody: {
  startDate: ISODateString;
  billingPolicy: SubscriptionBillingPolicyInput | undefined;
  lines: { quantity: number; variantId: string | number }[];
  shopifyPaymentMethodId?: string;
  stripeCustomerId?: string;
  sellingPlanId?: string | number;
  shippingInfo: PurchaseStateCreateShippingInfoInput;
}) {
  return typedFrontendVendorApi.postReq("/purchase-state", {
    reqBody,
  });
}

export function getSubscriptionEvents(shopifySubscriptionId: string) {
  return typedFrontendVendorApi.getReq("/purchase-state/:shopifySubscriptionId/events", {
    params: {
      shopifySubscriptionId,
    },
  });
}

export function putSubscriptionEventsAddComment(shopifySubscriptionId: string, text: string, authorName: string) {
  return typedFrontendVendorApi.postReq("/purchase-state/:shopifySubscriptionId/events/add-comment", {
    params: {
      shopifySubscriptionId,
    },
    reqBody: {
      text,
      authorName,
    },
  });
}

export function putSubscriptionEventsPinComment(shopifySubscriptionId: string, eventId: string) {
  return typedFrontendVendorApi.putReq("/purchase-state/:shopifySubscriptionId/events/:eventId/pin-comment", {
    params: {
      shopifySubscriptionId,
      eventId,
    },
  });
}

export function deleteSubscriptionEventsComment(shopifySubscriptionId: string, eventId: string) {
  return typedFrontendVendorApi.deleteReq(
    "/purchase-state/:shopifySubscriptionId/events/:eventId/remove-comment",
    {
      params: {
        shopifySubscriptionId,
        eventId,
      },
    }
  );
}

export function getSubscriptionBillsByIdOrShopifyId(shopifySubscriptionId: string, params: any) {
  return typedFrontendVendorApi.getReq("/purchase-state/:shopifySubscriptionId/bills", {
    params: {
      shopifySubscriptionId,
    },
    query: params ? serializePaginatedQueryValues(params) : undefined,
  });
}

export function putPauseCustomerPurchaseState(
  customerRelationshipId: string,
  customerPurchaseStateId: string,
  unpauseDate: ISODateString
) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/pause",
    {
      params: {
        customerRelationshipId,
        customerPurchaseStateId,
      },
      reqBody: {
        unpauseDate,
      },
    }
  );
}

export function putUnpauseCustomerPurchaseState(customerRelationshipId: string, customerPurchaseStateId: string) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/unpause",
    {
      params: {
        customerRelationshipId,
        customerPurchaseStateId,
      },
    }
  );
}

export function putCancelCustomerPurchaseState(customerRelationshipId: string, customerPurchaseStateId: string) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/cancel",
    {
      params: {
        customerRelationshipId,
        customerPurchaseStateId,
      },
    }
  );
}

export function putCancelPrepaidCustomerPurchaseState(
  customerRelationshipId: string,
  customerPurchaseStateId: string
) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/cancel-prepaid",
    {
      params: {
        customerRelationshipId,
        customerPurchaseStateId,
      },
    }
  );
}

export function putCPSShippingAddress(
  customerRelationshipId: string,
  customerPurchaseStateId: string,
  formattedValues: IMailingAddressJson
) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/address",
    {
      params: {
        customerRelationshipId,
        customerPurchaseStateId,
      },
      reqBody: formattedValues,
    }
  );
}

export function putActivateCustomerPurchaseState(
  customerRelationshipId: string,
  customerPurchaseStateId: string,
  nextBillingDate: Date | undefined
) {
  return typedFrontendVendorApi.putReq(
    "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/activate",
    {
      params: {
        customerRelationshipId,
        customerPurchaseStateId,
      },
      reqBody: {
        nextBillingDate: nextBillingDate ? ISODateString.toString(nextBillingDate) : undefined,
      },
    }
  );
}

export function putSendNowCustomerPurchaseState(customerPurchaseStateId: string, nextDeliveryDate: Date) {
  return typedFrontendVendorApi.putReq("/purchase-state/:customerPurchaseStateId/send-now", {
    params: {
      customerPurchaseStateId,
    },
    reqBody: {
      nextDeliveryDate,
    },
  });
}

export function putBulkPauseCustomerPurchaseStatesWithQuery({
  ids,
  tableProps,
  unpauseDate,
  queryFilterType,
}: {
  ids: "all" | string[];
  tableProps?: TableSettingProps;
  unpauseDate: ISODateString;
  queryFilterType?: "filterIn" | "filterLike";
}) {
  let newQueryParams: IDeserializedPaginatedQuery | undefined = {
    ...tableProps,
    pageSize: tableProps?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: tableProps?.pageNumber ? tableProps.pageNumber - 1 : 0,
    orderBy: {
      [tableProps?.orderByField ?? "shopifyId"]: tableProps?.orderByValue ?? "ASC",
    },
  };

  newQueryParams = getQueryParamsForFilters(newQueryParams, tableProps?.filter, queryFilterType);

  return typedFrontendVendorApi.putReq("/customer/purchase-state/bulk-pause", {
    query: serializePaginatedQueryValues(newQueryParams),
    reqBody: {
      customerPurchaseStateIds: ids,
      unpauseDate,
    },
  });
}
export function putBulkActivateCustomerPurchaseStatesWithQuery({
  ids,
  tableProps,
  queryFilterType,
}: {
  ids: "all" | string[];
  tableProps?: TableSettingProps;
  queryFilterType?: "filterIn" | "filterLike";
}) {
  let newQueryParams: IDeserializedPaginatedQuery | undefined = {
    ...tableProps,
    pageSize: tableProps?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: tableProps?.pageNumber ? tableProps.pageNumber - 1 : 0,
    orderBy: {
      [tableProps?.orderByField ?? "shopifyId"]: tableProps?.orderByValue ?? "ASC",
    },
  };
  newQueryParams = getQueryParamsForFilters(newQueryParams, tableProps?.filter, queryFilterType);

  return typedFrontendVendorApi.putReq("/customer/purchase-state/bulk-unpause", {
    query: serializePaginatedQueryValues(newQueryParams),
    reqBody: {
      customerPurchaseStateIds: ids,
    },
  });
}

export function putBulkCancelCustomerPurchaseStatesWithQuery({
  ids,
  tableProps,
  queryFilterType,
}: {
  ids: "all" | string[];
  tableProps?: TableSettingProps;
  queryFilterType?: "filterIn" | "filterLike";
}) {
  let newQueryParams: IDeserializedPaginatedQuery | undefined = {
    ...tableProps,
    pageSize: tableProps?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: tableProps?.pageNumber ? tableProps.pageNumber - 1 : 0,
    orderBy: {
      [tableProps?.orderByField ?? "shopifyId"]: tableProps?.orderByValue ?? "ASC",
    },
  };
  newQueryParams = getQueryParamsForFilters(newQueryParams, tableProps?.filter, queryFilterType);

  return typedFrontendVendorApi.putReq("/customer/purchase-state/bulk-cancel", {
    query: serializePaginatedQueryValues(newQueryParams),
    reqBody: {
      customerPurchaseStateIds: ids,
    },
  });
}

export function putBulkCustomerPurchaseStateSetNextBillingDate({
  ids,
  date,
  tableProps,
  queryFilterType,
}: {
  ids: "all" | string[];
  date: string;
  tableProps?: TableSettingProps;
  queryFilterType?: "filterIn" | "filterLike";
}) {
  let newQueryParams: IDeserializedPaginatedQuery | undefined = {
    ...tableProps,
    pageSize: tableProps?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: tableProps?.pageNumber ? tableProps.pageNumber - 1 : 0,
    orderBy: {
      [tableProps?.orderByField ?? "shopifyId"]: tableProps?.orderByValue ?? "ASC",
    },
  };
  newQueryParams = getQueryParamsForFilters(newQueryParams, tableProps?.filter, queryFilterType);

  return typedFrontendVendorApi.putReq("/customer/purchase-state/bulk-set-nextBillingDate", {
    query: serializePaginatedQueryValues(newQueryParams),
    reqBody: {
      customerPurchaseStateIds: ids,
      date,
    },
  });
}

export const loadCustomerPurchaseStates =
  ({
    queryParams,
  }: ILoadPaginatedForOrg): // paginatedQuery: ILoadPaginatedForOrg
  SmartrrThunkAction<
    | "LOADING_CUSTOMER_PURCHASE_STATES"
    | "ERROR_LOADING_CUSTOMER_PURCHASE_STATES"
    | "LOADED_CUSTOMER_PURCHASE_STATES"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      getCustomerPurchaseStates({ queryParams }),
      () =>
        dispatch({
          type: "LOADING_CUSTOMER_PURCHASE_STATES",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_CUSTOMER_PURCHASE_STATES",
          payload: {
            errorMessage: failure.message,
          },
        }),
      paginatedPurchaseStateWithUsersResponse =>
        dispatch({
          type: "LOADED_CUSTOMER_PURCHASE_STATES",
          payload: {
            customerPurchaseStates: paginatedPurchaseStateWithUsersResponse,
          },
        })
    );

export const loadCustomerPurchaseStateByIdOrShopifyId =
  (
    subscriptionId: string
  ): SmartrrThunkAction<
    "LOADING_CUSTOMER_PURCHASE_STATE" | "ERROR_LOADING_CUSTOMER_PURCHASE_STATE" | "LOADED_CUSTOMER_PURCHASE_STATE"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      getCustomerPurchaseStateByIdOrShopifyId(subscriptionId),
      () =>
        dispatch({
          type: "LOADING_CUSTOMER_PURCHASE_STATE",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_CUSTOMER_PURCHASE_STATE",
          payload: {
            errorMessage: failure.message,
          },
        }),
      purchaseState =>
        dispatch({
          type: "LOADED_CUSTOMER_PURCHASE_STATE",
          payload: {
            purchaseState,
          },
        })
    );

export const loadCustomerPurchaseStateIds =
  (): SmartrrThunkAction<
    | "LOADING_CUSTOMER_PURCHASE_STATE_IDS"
    | "ERROR_LOADING_CUSTOMER_PURCHASE_STATE_IDS"
    | "LOADED_CUSTOMER_PURCHASE_STATE_IDS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/purchase-state/ids"),
      () =>
        dispatch({
          type: "LOADING_CUSTOMER_PURCHASE_STATE_IDS",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_CUSTOMER_PURCHASE_STATE_IDS",
          payload: {
            errorMessage: failure.message,
          },
        }),
      ids =>
        dispatch({
          type: "LOADED_CUSTOMER_PURCHASE_STATE_IDS",
          payload: {
            ids,
          },
        })
    );

export const createCustomerPurchaseState =
  (reqBody: {
    startDate: ISODateString;
    billingPolicy: SubscriptionBillingPolicyInput | undefined;
    lines: { quantity: number; variantId: string | number }[];
    stripeCustomerId?: string;
    shopifyPaymentMethodId?: string;
    sellingPlanId?: string | number;
    shippingInfo: PurchaseStateCreateShippingInfoInput;
    status: "ACTIVE";
  }): SmartrrThunkAction<
    | "CREATING_CUSTOMER_PURCHASE_STATE"
    | "ERROR_CREATING_CUSTOMER_PURCHASE_STATE"
    | "CREATED_CUSTOMER_PURCHASE_STATE"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      postCreateSubscription(reqBody),
      () =>
        dispatch({
          type: "CREATING_CUSTOMER_PURCHASE_STATE",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_CREATING_CUSTOMER_PURCHASE_STATE",
          payload: {
            errorMessage: failure.message,
            status: failure.status,
          },
        }),
      customerPurchaseState =>
        dispatch({
          type: "CREATED_CUSTOMER_PURCHASE_STATE",
          payload: {
            customerPurchaseState,
          },
        })
    );

export const activateCustomerPurchaseState =
  ({
    customerRelationshipId,
    customerPurchaseStateId,
    nextBillingDate,
    addToast,
  }: {
    customerRelationshipId: string;
    customerPurchaseStateId: string;
    nextBillingDate: Date | undefined;
    addToast: (content: string) => void;
  }): SmartrrThunkAction<
    | "ACTIVATING_CUSTOMER_PURCHASE_STATE"
    | "ERROR_ACTIVATING_CUSTOMER_PURCHASE_STATE"
    | "ACTIVATED_CUSTOMER_PURCHASE_STATE"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putActivateCustomerPurchaseState(customerRelationshipId, customerPurchaseStateId, nextBillingDate),
      () =>
        dispatch({
          type: "ACTIVATING_CUSTOMER_PURCHASE_STATE",
          payload: {
            customerPurchaseStateId,
          },
        }),
      failure => {
        addToast("Error activating subscription");
        return dispatch({
          type: "ERROR_ACTIVATING_CUSTOMER_PURCHASE_STATE",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      res => {
        addToast("Subscription activated");
        return dispatch({
          type: "ACTIVATED_CUSTOMER_PURCHASE_STATE",
          payload: {
            newCustomerPurchaseState: res.newCustomerPurchaseState,
          },
        });
      }
    );

export const pauseCustomerPurchaseState =
  ({
    customerRelationshipId,
    customerPurchaseStateId,
    unpauseDate,
    addToast,
  }: {
    customerRelationshipId: string;
    customerPurchaseStateId: string;
    unpauseDate: ISODateString;
    addToast: (content: string) => void;
  }): SmartrrThunkAction<
    "PAUSING_CUSTOMER_PURCHASE_STATE" | "ERROR_PAUSING_CUSTOMER_PURCHASE_STATE" | "PAUSED_CUSTOMER_PURCHASE_STATE"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putPauseCustomerPurchaseState(customerRelationshipId, customerPurchaseStateId, unpauseDate),
      () =>
        dispatch({
          type: "PAUSING_CUSTOMER_PURCHASE_STATE",
          payload: {
            customerPurchaseStateId,
          },
        }),
      failure => {
        addToast("Error pausing subscription");
        return dispatch({
          type: "ERROR_PAUSING_CUSTOMER_PURCHASE_STATE",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      res => {
        addToast(
          `Subscription paused. Auto-resume set for ${toViewDate(ISODateString.fromString(unpauseDate), {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}.`
        );

        return dispatch({
          type: "PAUSED_CUSTOMER_PURCHASE_STATE",
          payload: {
            newCustomerPurchaseState: res.newCustomerPurchaseState,
          },
        });
      }
    );

export const cancelCustomerPurchaseState =
  ({
    customerRelationshipId,
    customerPurchaseStateId,
    addToast,
  }: {
    customerRelationshipId: string;
    customerPurchaseStateId: string;
    addToast: (content: string) => void;
  }): SmartrrThunkAction<
    | "CANCELLING_CUSTOMER_PURCHASE_STATE"
    | "ERROR_CANCELLING_CUSTOMER_PURCHASE_STATE"
    | "CANCELLED_CUSTOMER_PURCHASE_STATE"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putCancelCustomerPurchaseState(customerRelationshipId, customerPurchaseStateId),
      () =>
        dispatch({
          type: "CANCELLING_CUSTOMER_PURCHASE_STATE",
          payload: {
            customerPurchaseStateId,
          },
        }),
      failure => {
        addToast("Error canceling subscription");
        return dispatch({
          type: "ERROR_CANCELLING_CUSTOMER_PURCHASE_STATE",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      res => {
        addToast("Subscription canceled");
        return dispatch({
          type: "CANCELLED_CUSTOMER_PURCHASE_STATE",
          payload: {
            newCustomerPurchaseState: res.newCustomerPurchaseState,
          },
        });
      }
    );

export const cancelPrepaidCustomerPurchaseState =
  ({
    customerRelationshipId,
    customerPurchaseStateId,
    addToast,
  }: {
    customerRelationshipId: string;
    customerPurchaseStateId: string;
    addToast: (content: string) => void;
  }): SmartrrThunkAction<
    | "CANCELLING_CUSTOMER_PURCHASE_STATE"
    | "ERROR_CANCELLING_CUSTOMER_PURCHASE_STATE"
    | "CANCELLED_CUSTOMER_PURCHASE_STATE"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putCancelPrepaidCustomerPurchaseState(customerRelationshipId, customerPurchaseStateId),
      () =>
        dispatch({
          type: "CANCELLING_CUSTOMER_PURCHASE_STATE",
          payload: {
            customerPurchaseStateId,
          },
        }),
      failure => {
        addToast("Error canceling subscription");
        return dispatch({
          type: "ERROR_CANCELLING_CUSTOMER_PURCHASE_STATE",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      res => {
        addToast("Subscription canceled");
        return dispatch({
          type: "CANCELLED_CUSTOMER_PURCHASE_STATE",
          payload: {
            newCustomerPurchaseState: res.newCustomerPurchaseState,
          },
        });
      }
    );

export const bulkPauseCustomerPurchaseStates =
  ({
    ids,
    tableProps,
    unpauseDate,
    addToast,
    queryFilterType,
  }: {
    ids: "all" | string[];
    tableProps: TableSettingProps;
    unpauseDate?: ISODateString;
    addToast: (content: string) => void;
    queryFilterType?: "filterIn" | "filterLike";
  }): SmartrrThunkAction<
    | "BULK_PAUSING_CUSTOMER_PURCHASE_STATES"
    | "ERROR_BULK_PAUSING_CUSTOMER_PURCHASE_STATES"
    | "BULK_PAUSED_CUSTOMER_PURCHASE_STATES"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putBulkPauseCustomerPurchaseStatesWithQuery({ ids, tableProps, unpauseDate, queryFilterType }),
      () =>
        dispatch({
          type: "BULK_PAUSING_CUSTOMER_PURCHASE_STATES",
          payload: {
            customerPurchaseStateIds: ids,
          },
        }),
      failure => {
        addToast("Error pausing subscription(s). Try to reload the page.");
        return dispatch({
          type: "ERROR_BULK_PAUSING_CUSTOMER_PURCHASE_STATES",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      res => {
        if (ids === "all" || ids.length > res.failedSubscriptions.length) {
          if (unpauseDate) {
            addToast(
              `${ids === "all" ? "All" : ids.length} subscription(s) paused. Auto-resume set for ${toViewDate(
                ISODateString.fromString(unpauseDate),
                { day: "numeric", month: "long", year: "numeric" }
              )}.`
            );
          } else {
            addToast(`${ids === "all" ? "All" : ids.length} subscription(s) paused.`);
          }
        }
        return dispatch({
          type: "BULK_PAUSED_CUSTOMER_PURCHASE_STATES",
          payload: {
            customerPurchaseStates: res.newCustomerPurchaseState,
            failedSubscriptions: res.failedSubscriptions,
          },
        });
      }
    );

export const bulkActivateCustomerPurchaseStates =
  ({
    ids,
    tableProps,
    addToast,
    queryFilterType,
  }: {
    ids: string[] | "all";
    tableProps: TableSettingProps;
    addToast: (content: string) => void;
    queryFilterType?: "filterIn" | "filterLike";
  }): SmartrrThunkAction<
    | "BULK_UNPAUSING_CUSTOMER_PURCHASE_STATES"
    | "ERROR_BULK_UNPAUSING_CUSTOMER_PURCHASE_STATES"
    | "BULK_UNPAUSED_CUSTOMER_PURCHASE_STATES"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putBulkActivateCustomerPurchaseStatesWithQuery({ ids, tableProps, queryFilterType }),
      () =>
        dispatch({
          type: "BULK_UNPAUSING_CUSTOMER_PURCHASE_STATES",
          payload: {
            customerPurchaseStateIds: ids,
          },
        }),
      failure => {
        addToast("Error activating subscription(s). Try to reload the page.");
        return dispatch({
          type: "ERROR_BULK_UNPAUSING_CUSTOMER_PURCHASE_STATES",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      res => {
        if (ids === "all" || ids.length > res.failedSubscriptions.length) {
          addToast("Subscription(s) activated");
        }
        return dispatch({
          type: "BULK_UNPAUSED_CUSTOMER_PURCHASE_STATES",
          payload: {
            customerPurchaseStates: res.newCustomerPurchaseState,
            failedSubscriptions: res.failedSubscriptions,
          },
        });
      }
    );

export const bulkCancelCustomerPurchaseStates =
  ({
    ids,
    tableProps,
    addToast,
    queryFilterType,
  }: {
    ids: "all" | string[];
    tableProps: TableSettingProps;
    addToast: (content: string) => void;
    queryFilterType?: "filterIn" | "filterLike";
  }): SmartrrThunkAction<
    | "BULK_CANCELLING_CUSTOMER_PURCHASE_STATES"
    | "ERROR_BULK_CANCELLING_CUSTOMER_PURCHASE_STATES"
    | "BULK_CANCELLED_CUSTOMER_PURCHASE_STATES"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putBulkCancelCustomerPurchaseStatesWithQuery({ ids, tableProps, queryFilterType }),
      () =>
        dispatch({
          type: "BULK_CANCELLING_CUSTOMER_PURCHASE_STATES",
          payload: {
            customerPurchaseStateIds: ids,
          },
        }),
      failure => {
        addToast("Error cancelling subscription(s). Try to reload the page.");
        return dispatch({
          type: "ERROR_BULK_CANCELLING_CUSTOMER_PURCHASE_STATES",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      res => {
        if (ids === "all" || ids.length > res.failedSubscriptions.length) {
          addToast("Subscription(s) cancelled");
        }
        return dispatch({
          type: "BULK_CANCELLED_CUSTOMER_PURCHASE_STATES",
          payload: {
            customerPurchaseStates: res.newCustomerPurchaseState,
            failedSubscriptions: res.failedSubscriptions,
          },
        });
      }
    );

export const bulkCustomerPurchaseStateSetNextBillingDate =
  ({
    ids,
    tableProps,
    date,
    addToast,
    queryFilterType,
  }: {
    ids: "all" | string[];
    date: string;
    tableProps: TableSettingProps;
    addToast: (content: string) => void;
    queryFilterType?: "filterIn" | "filterLike";
  }): SmartrrThunkAction<
    | "BULK_SETTING_NEXTDATE_CUSTOMER_PURCHASE_STATES"
    | "ERROR_BULK_SETTING_NEXTDATE_CUSTOMER_PURCHASE_STATES"
    | "BULK_SET_NEXTDATE_CUSTOMER_PURCHASE_STATES"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putBulkCustomerPurchaseStateSetNextBillingDate({ ids, date, tableProps, queryFilterType }),
      () =>
        dispatch({
          type: "BULK_SETTING_NEXTDATE_CUSTOMER_PURCHASE_STATES",
          payload: {
            customerPurchaseStateIds: ids,
          },
        }),
      failure => {
        addToast("Error updating next order date for subscription(s). Try to reload the page.");
        return dispatch({
          type: "ERROR_BULK_SETTING_NEXTDATE_CUSTOMER_PURCHASE_STATES",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      res => {
        if (ids === "all" || ids.length > res.failedSubscriptions.length) {
          addToast(`Next order date updated`);
        }
        return dispatch({
          type: "BULK_SET_NEXTDATE_CUSTOMER_PURCHASE_STATES",
          payload: {
            customerPurchaseStates: res.newCustomerPurchaseState,
            failedSubscriptions: res.failedSubscriptions,
          },
        });
      }
    );

export const editCPSShippingAddress =
  ({
    customerRelationshipId,
    customerPurchaseStateId,
    formattedValues,
  }: ILoadPaginatedForOrg & {
    customerRelationshipId: string;
    customerPurchaseStateId: string;
    formattedValues: IMailingAddressJson;
  }): SmartrrThunkAction<
    "EDIT_CPS_SHIPPING_ADDRESS" | "ERROR_EDITING_CPS_SHIPPING_ADDRESS" | "EDITED_CPS_SHIPPING_ADDRESS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putCPSShippingAddress(customerRelationshipId, customerPurchaseStateId, formattedValues),
      () =>
        dispatch({
          type: "EDIT_CPS_SHIPPING_ADDRESS",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_EDITING_CPS_SHIPPING_ADDRESS",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "EDITED_CPS_SHIPPING_ADDRESS",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const deleteCustomerPurchaseStateDiscount =
  ({
    customerRelationshipId,
    customerPurchaseStateId,
    discountVendorId,
  }: ILoadPaginatedForOrg & {
    customerRelationshipId: string;
    customerPurchaseStateId: string;
    discountVendorId: string;
  }): SmartrrThunkAction<"DELETE_CPS_DISCOUNT" | "ERROR_DELETING_CPS_DISCOUNT" | "DELETED_CPS_DISCOUNT"> =>
  dispatch =>
    vendorAsyncDispatch(
      deleteCustomerPurchaseStateDiscountAPI(customerRelationshipId, customerPurchaseStateId, discountVendorId),
      () =>
        dispatch({
          type: "DELETE_CPS_DISCOUNT",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_DELETING_CPS_DISCOUNT",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "DELETED_CPS_DISCOUNT",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const addCustomerPurchaseStateDiscount =
  ({
    customerRelationshipId,
    customerPurchaseStateId,
    discountCode,
  }: ILoadPaginatedForOrg & {
    customerRelationshipId: string;
    customerPurchaseStateId: string;
    discountCode: string;
  }): SmartrrThunkAction<"ADD_CPS_DISCOUNT" | "ERROR_ADDING_CPS_DISCOUNT" | "ADDED_CPS_DISCOUNT"> =>
  dispatch =>
    vendorAsyncDispatch(
      postCustomerPurchaseStateApplyDiscountCodeAPI(
        customerRelationshipId,
        customerPurchaseStateId,
        discountCode
      ),
      () =>
        dispatch({
          type: "ADD_CPS_DISCOUNT",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_ADDING_CPS_DISCOUNT",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "ADDED_CPS_DISCOUNT",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const addCustomerPurchaseStateLineItem =
  ({
    cpsId,
    custRelId,
    price,
    quantity,
    sellingPlanId,
    variantId,
    skdIdx,
  }: ILoadPaginatedForOrg & {
    custRelId: string;
    cpsId: string;
    quantity: number;
    price: number;
    variantId: string;
    sellingPlanId: string | number;
    skdIdx?: number;
  }): SmartrrThunkAction<"ADD_CPS_LINE_ITEM" | "ERROR_ADDING_CPS_LINE_ITEM" | "ADDED_CPS_LINE_ITEM"> =>
  dispatch =>
    vendorAsyncDispatch(
      addCustomerPurchaseStateLineItemAPI(custRelId, cpsId, quantity, price, variantId, sellingPlanId, skdIdx),
      () =>
        dispatch({
          type: "ADD_CPS_LINE_ITEM",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_ADDING_CPS_LINE_ITEM",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "ADDED_CPS_LINE_ITEM",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const bulkAddCustomerPurchaseStateLineItem =
  ({
    cpsId,
    custRelId,
    lineItems,
  }: ILoadPaginatedForOrg & {
    custRelId: string;
    cpsId: string;
    lineItems: ILineItemToAdd[];
  }): SmartrrThunkAction<
    "BULK_ADD_CPS_LINE_ITEM" | "ERROR_BULK_ADDING_CPS_LINE_ITEM" | "BULK_ADDED_CPS_LINE_ITEM"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      bulkAddCustomerPurchaseStateLineItemAPI(custRelId, cpsId, lineItems),
      () =>
        dispatch({
          type: "BULK_ADD_CPS_LINE_ITEM",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_BULK_ADDING_CPS_LINE_ITEM",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "BULK_ADDED_CPS_LINE_ITEM",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const bulkUpdateCustomerPurchaseStateLineItem =
  ({
    cpsId,
    custRelId,
    lineItems,
  }: ILoadPaginatedForOrg & {
    custRelId: string;
    cpsId: string;
    lineItems: ILineItemToUpdate[];
  }): SmartrrThunkAction<
    "BULK_UPDATE_CPS_LINE_ITEM" | "ERROR_BULK_UPDATING_CPS_LINE_ITEM" | "BULK_UPDATED_CPS_LINE_ITEM"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      bulkUpdateCustomerPurchaseStateLineItemAPI(custRelId, cpsId, lineItems),
      () =>
        dispatch({
          type: "BULK_UPDATE_CPS_LINE_ITEM",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_BULK_UPDATING_CPS_LINE_ITEM",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "BULK_UPDATED_CPS_LINE_ITEM",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const bulkRemoveCustomerPurchaseStateLineItem =
  ({
    cpsId,
    custRelId,
    lineItemIds,
  }: ILoadPaginatedForOrg & {
    custRelId: string;
    cpsId: string;
    lineItemIds: string[];
  }): SmartrrThunkAction<
    "BULK_REMOVE_CPS_LINE_ITEM" | "ERROR_BULK_REMOVING_CPS_LINE_ITEM" | "BULK_REMOVED_CPS_LINE_ITEM"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      bulkRemoveCustomerPurchaseStateLineItemAPI(custRelId, cpsId, lineItemIds),
      () =>
        dispatch({
          type: "BULK_REMOVE_CPS_LINE_ITEM",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_BULK_REMOVING_CPS_LINE_ITEM",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "BULK_REMOVED_CPS_LINE_ITEM",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const putCustomerPurchaseStateLineItem =
  ({
    cpsId,
    custRelId,
    lineItemId,
    price,
    quantity,
  }: ILoadPaginatedForOrg & {
    custRelId: string;
    cpsId: string;
    lineItemId: string;
    price: number;
    quantity: number;
  }): SmartrrThunkAction<"UPDATE_CPS_LINE_ITEM" | "ERROR_UPDATING_CPS_LINE_ITEM" | "UPDATED_CPS_LINE_ITEM"> =>
  dispatch =>
    vendorAsyncDispatch(
      putCustomerPurchaseStateLineItemAPI(custRelId, cpsId, lineItemId, price, quantity),
      () =>
        dispatch({
          type: "UPDATE_CPS_LINE_ITEM",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_UPDATING_CPS_LINE_ITEM",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "UPDATED_CPS_LINE_ITEM",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const deleteCustomerPurchaseStateLineItem =
  ({
    cpsId,
    custRelId,
    lineItemId,
  }: ILoadPaginatedForOrg & {
    custRelId: string;
    cpsId: string;
    lineItemId: string;
  }): SmartrrThunkAction<"DELETE_CPS_LINE_ITEM" | "ERROR_DELETING_CPS_LINE_ITEM" | "DELETED_CPS_LINE_ITEM"> =>
  dispatch =>
    vendorAsyncDispatch(
      deleteCustomerPurchaseStateLineItemAPI(custRelId, cpsId, lineItemId),
      () =>
        dispatch({
          type: "DELETE_CPS_LINE_ITEM",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_DELETING_CPS_LINE_ITEM",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "DELETED_CPS_LINE_ITEM",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const putCustomerPurchaseStateSellingPlan =
  ({
    cpsId,
    custRelId,
    sellingPlanId,
  }: ILoadPaginatedForOrg & {
    custRelId: string;
    cpsId: string;
    sellingPlanId: number;
  }): SmartrrThunkAction<
    "UPDATE_CPS_SELLING_PLAN" | "ERROR_UPDATING_CPS_SELLING_PLAN" | "UPDATED_CPS_SELLING_PLAN"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putCustomerPurchaseStateSellingPlanAPI(custRelId, cpsId, sellingPlanId),
      () =>
        dispatch({
          type: "UPDATE_CPS_SELLING_PLAN",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_UPDATING_CPS_SELLING_PLAN",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "UPDATED_CPS_SELLING_PLAN",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const putCustomerPurchaseStateShippingPrice =
  ({
    cpsId,
    custRelId,
    price,
  }: ILoadPaginatedForOrg & {
    custRelId: string;
    cpsId: string;
    price: number;
  }): SmartrrThunkAction<
    "UPDATE_CPS_SHIPPING_PRICE" | "ERROR_UPDATING_CPS_SHIPPING_PRICE" | "UPDATED_CPS_SHIPPING_PRICE"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putCustomerPurchaseStateShippingPriceAPI(custRelId, cpsId, price),
      () =>
        dispatch({
          type: "UPDATE_CPS_SHIPPING_PRICE",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_UPDATING_CPS_SHIPPING_PRICE",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "UPDATED_CPS_SHIPPING_PRICE",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const putCustomerPurchaseStateSetNextBillingDate =
  ({
    cpsId,
    custRelId,
    billingDate,
  }: ILoadPaginatedForOrg & {
    custRelId: string;
    cpsId: string;
    billingDate: string;
  }): SmartrrThunkAction<"UPDATE_CPS_NEXT_DATE" | "ERROR_UPDATING_CPS_NEXT_DATE" | "UPDATED_CPS_NEXT_DATE"> =>
  dispatch =>
    vendorAsyncDispatch(
      putCustomerPurchaseStateSetNextBillingDateAPI(custRelId, cpsId, billingDate),
      () =>
        dispatch({
          type: "UPDATE_CPS_NEXT_DATE",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_UPDATING_CPS_NEXT_DATE",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "UPDATED_CPS_NEXT_DATE",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const putCustomerPurchaseStateSkip =
  ({
    cpsId,
    custRelId,
    billingDate,
  }: ILoadPaginatedForOrg & {
    custRelId: string;
    cpsId: string;
    billingDate: Date;
  }): SmartrrThunkAction<"SKIP_CPS_NEXT_ORDER" | "ERROR_SKIPPING_CPS_NEXT_ORDER" | "SKIPPED_CPS_NEXT_ORDER"> =>
  dispatch =>
    vendorAsyncDispatch(
      putCustomerPurchaseStateSkipAPI(custRelId, cpsId, billingDate),
      () =>
        dispatch({
          type: "SKIP_CPS_NEXT_ORDER",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_SKIPPING_CPS_NEXT_ORDER",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "SKIPPED_CPS_NEXT_ORDER",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const putCustomerPurchaseStateUnskip =
  ({
    cpsId,
    custRelId,
    billingDate,
  }: ILoadPaginatedForOrg & {
    custRelId: string;
    cpsId: string;
    billingDate: Date;
  }): SmartrrThunkAction<
    "UNSKIP_CPS_NEXT_ORDER" | "ERROR_UNSKIPPING_CPS_NEXT_ORDER" | "UNSKIPPED_CPS_NEXT_ORDER"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putCustomerPurchaseStateUnskipAPI(custRelId, cpsId, billingDate),
      () =>
        dispatch({
          type: "UNSKIP_CPS_NEXT_ORDER",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_UNSKIPPING_CPS_NEXT_ORDER",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      newCustomerPurchaseState => {
        return dispatch({
          type: "UNSKIPPED_CPS_NEXT_ORDER",
          payload: {
            newCustomerPurchaseState,
          },
        });
      }
    );

export const swapSubscriptionLineItem =
  ({
    customerPurchaseStateId,
    customerRelationshipId,
    lineItemId,
    quantity,
    sellingPlanId,
    variantId,
    skdIdx,
  }: ILoadPaginatedForOrg & {
    customerRelationshipId: string;
    customerPurchaseStateId: string;
    lineItemId: string;
    variantId: string;
    quantity: number;
    sellingPlanId: string;
    skdIdx?: number;
  }): SmartrrThunkAction<
    | "SWAP_PURCHASE_STATE_LINE_ITEM"
    | "ERROR_SWAPPING_PURCHASE_STATE_LINE_ITEM"
    | "SWAPPED_PURCHASE_STATE_LINE_ITEM"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq(
        "/customer/:customerRelationshipId/purchase-state/:customerPurchaseStateId/line-item/:lineItemId/swap",
        {
          reqBody: {
            quantity,
            variantId,
            sellingPlanId,
            skdIdx,
          },
          params: {
            customerPurchaseStateId,
            customerRelationshipId,
            lineItemId,
          },
        }
      ),
      () =>
        dispatch({
          type: "SWAP_PURCHASE_STATE_LINE_ITEM",
          payload: undefined,
        }),
      () =>
        dispatch({
          type: "ERROR_SWAPPING_PURCHASE_STATE_LINE_ITEM",
          payload: {
            errorMessage: "error swapping line item",
          },
        }),
      purchaseState =>
        dispatch({
          type: "SWAPPED_PURCHASE_STATE_LINE_ITEM",
          payload: {
            newCustomerPurchaseState: purchaseState,
          },
        })
    );

export const sendNowPurchaseState =
  ({
    customerPurchaseStateId,
    nextDeliveryDate,
  }: ILoadPaginatedForOrg & {
    customerPurchaseStateId: string;
    nextDeliveryDate: Date;
  }): SmartrrThunkAction<
    "SEND_NOW_PURCHASE_STATE" | "ERROR_SENDING_NOW_PURCHASE_STATE" | "SENT_NOW_PURCHASE_STATE"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putSendNowCustomerPurchaseState(customerPurchaseStateId, nextDeliveryDate),
      () =>
        dispatch({
          type: "SEND_NOW_PURCHASE_STATE",
          payload: undefined,
        }),
      () =>
        dispatch({
          type: "ERROR_SENDING_NOW_PURCHASE_STATE",
          payload: {
            errorMessage: "Error sending order",
          },
        }),
      purchaseState =>
        dispatch({
          type: "SENT_NOW_PURCHASE_STATE",
          payload: {
            newCustomerPurchaseState: purchaseState,
          },
        })
    );

export const addNotePurchaseState =
  ({
    customerPurchaseStateId,
    noteText,
  }: ILoadPaginatedForOrg & {
    customerPurchaseStateId: string;
    noteText?: string;
  }): SmartrrThunkAction<
    "ADD_NOTE_PURCHASE_STATE" | "ERROR_ADDING_NOTE_PURCHASE_STATE" | "ADDED_NOTE_PURCHASE_STATE"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putCustomerPurchaseStateUpdateNote(customerPurchaseStateId, noteText),
      () =>
        dispatch({
          type: "ADD_NOTE_PURCHASE_STATE",
          payload: undefined,
        }),
      () =>
        dispatch({
          type: "ERROR_ADDING_NOTE_PURCHASE_STATE",
          payload: {
            errorMessage: "Error adding notes",
          },
        }),
      purchaseState =>
        dispatch({
          type: "ADDED_NOTE_PURCHASE_STATE",
          payload: {
            newCustomerPurchaseState: purchaseState,
          },
        })
    );

export const bulkSkipCustomerPurchaseState =
  ({
    customerPurchaseStateId,
    customerRelationshipId,
    dates,
  }: ILoadPaginatedForOrg & {
    customerPurchaseStateId: string;
    customerRelationshipId: string;
    dates: ISODateString[];
  }): SmartrrThunkAction<
    "BULK_SKIP_CPS_NEXT_ORDERS" | "ERROR_BULK_SKIPPING_CPS_NEXT_ORDERS" | "BULK_SKIPPED_CPS_NEXT_ORDERS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      bulkSkipCustomerPurchaseStateAPI(customerRelationshipId, customerPurchaseStateId, dates),
      () =>
        dispatch({
          type: "BULK_SKIP_CPS_NEXT_ORDERS",
          payload: undefined,
        }),
      () =>
        dispatch({
          type: "ERROR_BULK_SKIPPING_CPS_NEXT_ORDERS",
          payload: {
            errorMessage: "Error adding notes",
          },
        }),
      purchaseState =>
        dispatch({
          type: "BULK_SKIPPED_CPS_NEXT_ORDERS",
          payload: {
            newCustomerPurchaseState: purchaseState,
          },
        })
    );

export const bulkUnskipCustomerPurchaseState =
  ({
    customerPurchaseStateId,
    customerRelationshipId,
    dates,
  }: ILoadPaginatedForOrg & {
    customerPurchaseStateId: string;
    customerRelationshipId: string;
    dates: ISODateString[];
  }): SmartrrThunkAction<
    "BULK_UNSKIP_CPS_NEXT_ORDERS" | "ERROR_BULK_UNSKIPPING_CPS_NEXT_ORDERS" | "BULK_UNSKIPPED_CPS_NEXT_ORDERS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      bulkUnskipCustomerPurchaseStateAPI(customerRelationshipId, customerPurchaseStateId, dates),
      () =>
        dispatch({
          type: "BULK_UNSKIP_CPS_NEXT_ORDERS",
          payload: undefined,
        }),
      () =>
        dispatch({
          type: "ERROR_BULK_UNSKIPPING_CPS_NEXT_ORDERS",
          payload: {
            errorMessage: "Error adding notes",
          },
        }),
      purchaseState =>
        dispatch({
          type: "BULK_UNSKIPPED_CPS_NEXT_ORDERS",
          payload: {
            newCustomerPurchaseState: purchaseState,
          },
        })
    );
